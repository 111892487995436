import React from "react";
import { useCareersPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import { Hero, Slices } from "@organisms";
// import { Section, Container, Text } from "@base";
// import { Iframe } from "@ui";
// import { getTailwindColor } from "@utils";

const CareersPage = pageProps => {
  const { hero, slices, meta } = useCareersPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {hero.heading && <Hero {...hero} />}
      <Slices slices={slices} />
    </DefaultLayout>
  );
};

export default CareersPage;
